
import { mapState, mapGetters, mapActions } from 'vuex'
import UIHeader from '@/layouts/components/UIHeader/UIHeader.vue'
import PDEToast from '@/components/pde/PDEToast.vue'

export default {
  name: 'HeaderOnly',

  components: {
    UIHeader,
    PDEToast,
  },

  computed: {
    ...mapState('account', ['userDetailsLoaded']),
    ...mapGetters('toast', ['currentToast']),
  },

  methods: {
    ...mapActions('toast', ['closeToast']),
  },

}
